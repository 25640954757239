<template>
  <div
    v-show="barcodeScannerVisible"
    class="absolute left-0 top-0 h-[calc(100dvh)] w-full"
    v-bind="$attrs"
  >
    <video
      id="stream"
      ref="uiBarcodeScannerEl"
      class="size-full object-cover"
    />
    <span
      class="absolute left-1/2 top-1/2 aspect-square w-2/3 -translate-x-1/2 -translate-y-1/2 border-8 border-notification-success opacity-50"
    />
    <span
      class="absolute left-1/2 top-1/2 h-2 -translate-x-1/2 -translate-y-1/2 bg-errors opacity-50"
      style="width: calc(66.666667% - 16px)"
    />
    <div
      class="sticky bottom-0 flex h-16 w-full cursor-pointer items-center justify-center font-normal text-blue-150"
      style="background-color: #373131"
      @click="disableBarcodeScanner"
    >
      {{ labels.cancel }}
    </div>
  </div>

  <UiToastMessage
    v-if="msgErrorVisible"
    id="error-camera"
    type="error"
    :message="labels.msgError"
    @close="msgErrorVisible = false"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import UiToastMessage from '../UiToastMessage/UiToastMessage.vue'

interface UiBarcodeScanner {
  labels: {
    cancel: string
    msgError: string
  }
}
defineProps<UiBarcodeScanner>()
const emit = defineEmits<{
  (e: 'found-barcode', barcode: string): void
}>()
const uiBarcodeScannerEl = ref<any | null>(null)
const barcodeScannerVisible = ref<boolean>(false)
const detectedBarcode = ref<number>(-1)
const msgErrorVisible = ref<boolean>(false)
async function displayBarcodeScanner() {
  if (navigator.mediaDevices) {
    let stream = {} as MediaStream
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: {
            ideal: 'environment',
          },
        },
        audio: false,
      })
      uiBarcodeScannerEl.value.srcObject = stream
      await uiBarcodeScannerEl.value?.play()

      // Apply camera settings to improve focus and white balance if supported
      const videoTrack = stream.getVideoTracks()?.[0]
      if (videoTrack) {
        const capabilities = videoTrack.getCapabilities()
        const cameraSettings: MediaTrackConstraintSet = {}
        if (capabilities?.focusMode?.includes('continuous')) {
          cameraSettings.focusMode = 'continuous'
        }
        if (capabilities?.whiteBalanceMode?.includes('continuous')) {
          cameraSettings.whiteBalanceMode = 'continuous'
        }
        await videoTrack.applyConstraints({ advanced: [cameraSettings] })
      }

      const barcodeDetector = new BarcodeDetector({
        formats: [
          'code_39',
          'code_93',
          'codabar',
          'ean_13',
          'ean_8',
          'code_128',
          'itf',
          'upc_a',
          'upc_e',
        ],
      })
      barcodeScannerVisible.value = true
      detectedBarcode.value = window.setInterval(async () => {
        const barcodes = await barcodeDetector.detect(uiBarcodeScannerEl.value)
        if (barcodes.length <= 0) { return }
        emit('found-barcode', barcodes[0].rawValue)
      }, 1000)
    }
    catch (e) {
      console.error(e)
      msgErrorVisible.value = true
    }
  }
}
function disableBarcodeScanner() {
  barcodeScannerVisible.value = false
  window.clearInterval(detectedBarcode.value)
  const tracks = uiBarcodeScannerEl?.value?.srcObject?.getTracks()
  tracks.forEach((track: MediaStreamTrack) => track.stop())

  uiBarcodeScannerEl.value.srcObject = null
}

defineExpose({
  uiBarcodeScannerEl,
  displayBarcodeScanner,
  disableBarcodeScanner,
})
</script>
